<template>
  <div class="orderTest-container">
    <!-- NAME[epic=财务] 凭证 -->
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="70px"
    >
      <el-form-item label="凭证: 记-" prop="code">
        <el-input v-model="form.code" style="width: 140px"></el-input>
      </el-form-item>
      <el-form-item label="" prop="type">
        <el-select
          v-model="form.type"
          clearable
          style="width: 140px"
          placeholder="类型"
        >
          <el-option
            v-for="(i, idx) in typeList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <!-- 组件  科目类型 -->
        <type-input
          ref="typeInput"
          style="width: 140px"
          @select-id="financialId"
        ></type-input>
      </el-form-item>
      <el-form-item label="" prop="status">
        <el-select
          v-model="form.status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="bill_code">
        <el-input
          v-model="form.bill_code"
          placeholder="单据编号"
          style="width: 140px"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="note">
        <el-input
          v-model="form.note"
          placeholder="摘要"
          style="width: 140px"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="resetForm">重置</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleAdd">添加凭证</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      :span-method="firstSpanMethod"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="" width="50">
        <template #default="{ row }">
          {{ row.idx }}
        </template>
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.prop == 'bill_code'">
            <span class="underline" @click="checkDetail(row)">
              {{ row[item.prop] }}
            </span>
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="200px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>

          <el-button
            v-if="row.status == 1"
            type="text"
            @click.native.prevent="handleEdit($index, row)"
          >
            修改
          </el-button>
          <el-button
            v-if="row.status == 1"
            type="text"
            @click.native.prevent="handleReview($index, row)"
          >
            审核
          </el-button>
          <el-button
            v-if="row.status != 3 && row.type_text !== '系统生成'"
            type="text"
            @click.native.prevent="handleDelete($index, row)"
          >
            作废
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleCopyRow($index, row)"
          >
            打印
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" @refresh="fetchData"></edit>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="PZ"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <saleOrderDetail ref="saleOrderDetail"></saleOrderDetail>
    <baosunOrder ref="baosunOrder"></baosunOrder>
    <baoyiOrder ref="baoyiOrder"></baoyiOrder>
    <caogouOrder ref="caogouOrder"></caogouOrder>
    <caigoutuihuoOrder ref="caigoutuihuoOrder"></caigoutuihuoOrder>
    <duizhang ref="Reconciliation"></duizhang>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { dateFormat } from '@/utils/Time.js'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { getList, deleteRow } from '@/api/financial'
  import TypeInput from './components/typeInput'
  import Edit from './components/edit'
  import saleOrderDetail from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import baosunOrder from '@/views/project/treasury/requisition/Lossreport/components/goodslink.vue'
  import baoyiOrder from '@/views/project/treasury/requisition/baoYiDan/component/create.vue'
  import caogouOrder from '@/views/project/purchase/purchaseDoc/purchaseOrder2/components/check.vue'
  import caigoutuihuoOrder from '@/views/project/purchase/purchaseDoc/purchaseReturn/components/check.vue'
  import duizhang from '@/views/project/sale/control/sale/components/duizhang.vue'

  export default {
    name: 'PingZheng',
    components: {
      Edit,
      TypeInput,
      RuilangDisplayDialog,
      saleOrderDetail,
      baosunOrder,
      baoyiOrder,
      caogouOrder,
      caigoutuihuoOrder,
      duizhang,
    },
    data() {
      return {
        data_id: 0,
        typeList: [
          {
            id: 1,
            name: '系统',
          },
          {
            id: 2,
            name: '手动生成',
          },
        ],
        statusList: [
          {
            id: 1,
            name: '未审核',
          },
          {
            id: 2,
            name: '已审核',
          },
          {
            id: 3,
            name: '作废',
          },
        ],
        time: [],
        form: {
          pageSize: 10, //
          pageNo: 1, //
          status: '', //状态1未审核2已审核3作废
          type: '', //1系统2手动生成
          start_time: '', //开始时间
          end_time: '', //结束时间
          note: '', //摘要
          subject_id: '', //科目id
          bill_code: '', //单据编号
          code: '', //凭证号
        },
        list: [],
        checkList: [
          '日期',
          '凭证记号',
          '单据号',
          '摘要',
          '科目',
          '借',
          '贷',
          '类型',
          '状态',
          '制单人',
          '审核人',
        ],
        columns: [
          {
            order: 1,
            label: '日期',
            prop: 'create_at',
            width: '110',
          },
          {
            order: 2,
            label: '凭证记号',
            prop: 'code',
            width: '100',
          },
          {
            order: 3,
            label: '单据号',
            prop: 'bill_code',
            width: '110',
          },
          {
            order: 4,
            label: '摘要',
            prop: 'note',
            width: '90px',
          },
          {
            order: 5,
            label: '科目',
            prop: 'subject_name',
            width: '90px',
          },
          {
            order: 6,
            label: '借',
            prop: 'less_money',
            width: '90px',
          },
          {
            order: 7,
            label: '贷',
            prop: 'add_money',
            width: '90px',
          },
          {
            order: 8,
            label: '类型',
            prop: 'type_text',
            width: '',
          },
          {
            order: 9,
            label: '状态',
            prop: 'status_text',
            width: '',
          },
          {
            order: 10,
            label: '制单人',
            prop: 'add_name',
            width: '',
          },
          {
            order: 11,
            label: '审核人',
            prop: 'check_name',
            width: '',
          },
        ],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        spanArr: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.initTime()
      this.fetchData()
    },
    mounted() {},
    methods: {
      initTime() {
        let time = dateFormat(new Date())
        this.time = [time, time]
        this.form.start_time = time
        this.form.end_time = time
        /**
         * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
         */
        // this.form.start_time = '2021-01-01'
        // this.form.end_time = '2021-03-01'
      },
      async fetchData() {
        this.loading = true
        let { data, totalCount, msg } = await getList(this.form)
        console.log(data, totalCount)
        let this_ = this
        let idx = 1
        data.forEach((item, index) => {
          if (index !== data.length - 1) {
            if (data[index].id != data[index + 1].id) {
              item.idx = idx
              idx++
            } else {
              item.idx = idx
            }
          } else {
            item.idx = 10
          }
        })
        this.list = data
        this.total = totalCount
        this.loading = false
        this.setrowspans()
      },
      // 合并列
      setrowspans() {
        let contactDot = 0
        let spanArr = []
        this.list.forEach((item, index) => {
          if (index === 0) {
            spanArr.push(1)
          } else {
            if (item.id === this.list[index - 1].id) {
              spanArr[contactDot] += 1
              spanArr.push(0)
            } else {
              contactDot = index
              spanArr.push(1)
            }
          }
        })
        this.spanArr = spanArr
      },
      firstSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (
          columnIndex === 1 ||
          columnIndex === 0 ||
          columnIndex === 2 ||
          columnIndex === 3 ||
          columnIndex === 4 ||
          columnIndex === 9 ||
          columnIndex === 10 ||
          columnIndex === 11 ||
          columnIndex === 12 ||
          columnIndex === 13
        ) {
          if (this.spanArr[rowIndex]) {
            return {
              rowspan: this.spanArr[rowIndex],
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 1:查看 2:编辑 3:审核 4:添加
      handleCheck(index, row) {
        console.log(row.id)
        this.$refs.edit.type = 1
        this.$refs.edit.id = row.id
        this.$refs.edit.showDialog = true
        this.$refs.edit.title = '记账凭证'
      },
      handleEdit(index, row) {
        console.log(row.id)
        this.$refs.edit.type = 2
        this.$refs.edit.id = row.id
        this.$refs.edit.showDialog = true
        this.$refs.edit.title = '修改记账凭证'
      },
      handleReview(index, row) {
        console.log(row.id)
        this.$refs.edit.type = 3
        this.$refs.edit.id = row.id
        this.$refs.edit.showDialog = true
        this.$refs.edit.title = '审核记账凭证'
      },
      handleDelete(index, row) {
        console.log(row.id)
        // deleteRow
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteRow({
              id: row.id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('作废成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleCopyRow(index, row) {
        console.log(row.id)
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      resetForm() {
        this.$refs.form.resetFields()
        this.$refs.typeInput.resetForm()
        this.form.subject_id = ''
        this.time = []
      },
      handleAdd() {
        this.$refs.edit.type = 4
        this.$refs.edit.showDialog = true
        this.$refs.edit.title = '添加记账凭证'
      },
      financialId(val) {
        this.form.subject_id = val
      },
      // 查看单号详情
      checkDetail(row) {
        console.log('r', row)
        let id = row.bill_id
        let type = row.bill_code.slice(0, 2)
        console.log('type', type)
        // 销售单XS  采购单CG  采购退货单CT  对账单（对账ID）DZ  报损单BS   报溢单BY
        // baosunOrder
        // baoyiOrder
        // caogouOrder
        // caigoutuihuoOrder
        switch (type) {
          case 'XS':
            this.$refs.saleOrderDetail.isshowDialog = true
            this.$refs.saleOrderDetail.id = id
            break
          case 'CG':
            this.$refs.caogouOrder.id = id
            this.$refs.caogouOrder.showDialog = true
            break
          case 'CT':
            this.$refs.caigoutuihuoOrder.id = id
            this.$refs.caigoutuihuoOrder.showDialog = true
            break
          case 'BS':
            this.$refs.baosunOrder.showlink = true
            this.$refs.baosunOrder.isEdit = true
            row.id = id
            this.$refs.baosunOrder.handlerinfo(row)
            break
          case 'BY':
            this.$refs.baoyiOrder.showDialog = true
            this.$refs.baoyiOrder.id = id
            // type1添加 2查看 3审核
            this.$refs.baoyiOrder.type = 2
            this.$refs.baoyiOrder.title = '报溢单详情'
            break
          case '对账':
            this.$refs.Reconciliation.type = 2
            this.$refs.Reconciliation.id = id
            this.$refs.Reconciliation.duizhang_id = id
            this.$refs.Reconciliation.caozuo = 2
            // this.$refs.Reconciliation.endTime = row.date.slice(
            //   row.date.indexOf('--') + 2
            // )
            // this.$refs.Reconciliation.startTime = row.date.slice(
            //   0,
            //   row.date.indexOf('--')
            // )

            this.$refs.Reconciliation.showDialog = true
            break

          default:
            break
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
